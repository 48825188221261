import gsap from 'gsap';

export default () => {
  const clickCard = gsap.utils.toArray('.production-chain_wrapper');

  clickCard.forEach((element) => {
    const text = element.querySelector('.text-style-3lines');
    const icon = element.querySelector('.production-chain_expand-icon');

    const tl = gsap.timeline({
      paused: true,
    });

    tl.set(text, { webkitLineClamp: 99 }).set(icon, { rotate: -90 });

    element.addEventListener('click', () => {
      if (tl.progress() === 1) {
        tl.reverse();
        return;
      }

      if (tl.progress() === 0) {
        tl.play();
      }
    });
  });
};
