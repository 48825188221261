import gsap from 'gsap';

export default () => {
  const mm = gsap.matchMedia();

  const gatewayItems = gsap.utils.toArray('.gateway_item');

  gatewayItems.forEach((item) => {
    const bgImage = item.querySelector('.gateway_bg-image');

    const tl = gsap.timeline({
      paused: true,
    });

    tl.to(item, {
      flex: '0 0 60%',
      duration: 0.5,
      paddingBottom: '3rem',
      borderColor: 'transparent',
    }).to(
      bgImage,
      {
        opacity: 1,
        duration: 0.5,
      },
      '<'
    );

    item.addEventListener('mouseenter', () => {
      mm.add('(min-width: 992px)', () => {
        tl.play();
      });
    });

    item.addEventListener('mouseleave', () => {
      mm.add('(min-width: 992px)', () => {
        tl.reverse();
      });
    });
  });
};
