import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';

export default () => {
  const refreshScrollTriggerImageLoaded = () => {
    function handleLazyLoad(config = {}) {
      const lazyImages = gsap.utils.toArray("img[loading='lazy']");
      const timeout = gsap.delayedCall(config.timeout || 1, ScrollTrigger.refresh).pause();
      const lazyMode = config.lazy !== false;
      let imgLoaded = lazyImages.length;
      const onImgLoad = () =>
        lazyMode ? timeout.restart(true) : --imgLoaded || ScrollTrigger.refresh();
      lazyImages.forEach((img, i) => {
        lazyMode || (img.loading = 'eager');
        img.naturalWidth ? onImgLoad() : img.addEventListener('load', onImgLoad);
      });
    }

    // usage: you can optionally set lazy to false to change all images to load="eager". timeout is how many seconds it throttles the loading events that call ScrollTrigger.refresh()
    handleLazyLoad({ lazy: true, timeout: 10 });
  };

  gsap.registerPlugin(ScrollTrigger);

  gsap.defaults({
    ease: 'power4.out',
    duration: 1,
  });

  const heroFade = () => {
    const tl = gsap.timeline({
      paused: false,
      delay: 0.5,
    });

    tl.to('[hero-animation="navigation"]', { opacity: 1, duration: 1 }, '<')
      .to('[hero-animation="heading"]', {
        opacity: 1,
        duration: 1,
      })
      .to('[hero-animation="subheading"]', { opacity: 1, duration: 1 }, '>')
      .from('[hero-animation="button"]', { y: 30, duration: 1 }, '<')
      .to('[hero-animation="button"]', { opacity: 1, duration: 1 }, '<')
      .to('[hero-animation="customer-logo"]', { opacity: 1, duration: 1 }, '>');
  };

  const kpiHead = () => {
    const headers = gsap.utils.toArray('[kpi-animation-head="wrapper"]');

    headers.forEach((header) => {
      const gradientType1 = header.querySelector('[kpi-animation-head="kpi-gradient-1"]');
      const gradientType2 = header.querySelector('[kpi-animation-head="kpi-gradient-2"]');
      const heading = header.querySelector('[kpi-animation-head="heading"]');
      const subheading = header.querySelector('[kpi-animation-head="subheading"]');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: header,
          start: 'top center',
          scrub: false,
          markers: false,
        },
      });

      if (gradientType1) {
        tl.fromTo(
          gradientType1,
          {
            opacity: 0,
            scale: 2,
          },
          {
            opacity: 1,
            scale: 0.9,
            duration: 1,
          }
        )
          .to(gradientType1, { scale: 1, duration: 0.5, ease: 'power4.out' }, '>-0.5')
          .from(heading, {
            opacity: 0,
            y: 50,
            duration: 1,
          })
          .from(subheading, {
            opacity: 0,
            y: 50,
            duration: 1,
          });
      }

      if (gradientType2) {
        tl.from(gradientType2, {
          scale: 0,
          duration: 1.5,
        })
          .from(heading, {
            opacity: 0,
            y: 50,
            duration: 1,
          })
          .from(subheading, {
            opacity: 0,
            y: 50,
            duration: 1,
          });
      }
    });
  };

  const kpiItems = () => {
    const wrapper = gsap.utils.toArray('[kpi-animation-grid="wrapper"]');

    wrapper.forEach((element) => {
      const items = gsap.utils.toArray('[kpi-animation-grid="item"]', element);

      items.forEach((item) => {
        const bigNumber = item.querySelector('[kpi-animation-grid="big-number"]');
        const heading = item.querySelector('[kpi-animation-grid="heading"]');
        const paragraph = item.querySelector('[kpi-animation-grid="paragraph"]');
        const icon = item.querySelector('[kpi-animation-grid="icon"]');

        const splitText = new SplitType(bigNumber);
        const splitTextElements = gsap.utils.toArray(splitText.chars);

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top center',
            scrub: false,
            markers: false,
          },
        });

        if (bigNumber) {
          tl.from(splitTextElements, {
            opacity: 0,
            y: 50,
            duration: 0.5,
            ease: 'power4.out',
            stagger: 0.2,
          })
            .from(
              heading,
              {
                opacity: 0,
                y: 50,
                duration: 1.5,
              },
              '<+=0.5'
            )
            .from(
              paragraph,
              {
                opacity: 0,
                y: 50,
                duration: 1.5,
              },
              '<'
            );
        }

        if (icon) {
          tl.from(icon, {
            opacity: 0,
            y: 50,
            duration: 0.5,
            ease: 'power4.out',
          })
            .from(
              heading,
              {
                opacity: 0,
                y: 50,
                duration: 1.5,
              },
              '<'
            )
            .from(
              paragraph,
              {
                opacity: 0,
                y: 50,
                duration: 1.5,
              },
              '<'
            );
        }
      });
    });
  };

  const kpiQuote = () => {
    const wrapper = gsap.utils.toArray('[kpi-animation-quote="wrapper"]');

    wrapper.forEach((element) => {
      const text = element.querySelector('[kpi-animation-quote="text"]');
      const button = element.querySelector('[kpi-animation-quote="button"]');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top center',
          scrub: false,
          markers: false,
        },
      });

      tl.from(text, {
        opacity: 0,
        y: 50,
        duration: 1,
      }).from(
        button,
        {
          opacity: 0,
          y: 50,
          duration: 1,
        },
        '<0.5'
      );
    });
  };

  const aiComplexity = () => {
    const wrapper = gsap.utils.toArray('[ai-complexity-animation="wrapper"]');

    wrapper.forEach((element) => {
      const item = gsap.utils.toArray('[ai-complexity-animation="item"]', element);

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'center-=25% center',
          scrub: false,
          markers: false,
        },
      });

      tl.from(item, {
        opacity: 0,
        y: 50,
        duration: 1,
        stagger: 0.5,
      });
    });
  };

  const verticalLine = () => {
    const verticalLines = gsap.utils.toArray('[global-animation="vertical-line"]');

    verticalLines.forEach((element) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top center',
          scrub: false,
          markers: false,
        },
      });

      tl.from(element, {
        height: 0,
        duration: 1,
        ease: 'power4.out',
      });
    });
  };

  refreshScrollTriggerImageLoaded(); // creates janky scrolling in safari
  heroFade();
  kpiHead();
  kpiQuote();
  verticalLine();
  aiComplexity();
  kpiItems();
};
