import { createApp } from 'petite-vue';

export default () => {
  $(document).ready(() => {
    $(window).keydown((event) => {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  });

  createApp({
    activeGroup: '1',
    lastGroup: [],
    form: {
      industry: '',
      motivation: '',
      rejectRate3a: '',
      followupCost3b: '',
      netSellingPrice4a: '',
      frequency4b: '',
      yearlyProductionRate: '',
      pastQualityAssurance: '',
      staffCost: '',
    },
    path: '',
    frr5: '',
    frr10: '',
    frr20: '',
    rfn60: '',
    rfn40: '',
    rfn20: '',
    showResults: false,
    userURL: 'user-url',
    previousGroup() {
      this.activeGroup = this.lastGroup.pop();
    },
    nextGroup(group) {
      this.lastGroup.push(this.activeGroup);
      this.activeGroup = group;

      this.formatNumber();
    },
    checkUserURL() {
      const userURL = sessionStorage.getItem('initial-userURL');

      if (userURL) {
        this.userURL = userURL;
      } else {
        this.userURL = window.location.href;
      }
    },
    radioState(radio) {
      const groupAttribute = radio.getAttribute('name');

      const radioButtons = document.querySelectorAll(`input[name="${groupAttribute}"]`);

      radioButtons.forEach((button) => {
        if (button.checked === true) {
          button.parentElement.classList.add('is-active');
        }

        if (button.checked === false) {
          button.parentElement.classList.remove('is-active');
        }
      });
    },
    formatNumber() {
      this.form.rejectRate3a =
        this.form.rejectRate3a !== '' ? parseInt(this.form.rejectRate3a, 10) : '';

      this.form.followupCost3b =
        this.form.followupCost3b !== '' ? parseInt(this.form.followupCost3b, 10) : '';

      this.form.netSellingPrice4a =
        this.form.netSellingPrice4a !== '' ? parseInt(this.form.netSellingPrice4a, 10) : '';

      this.form.frequency4b =
        this.form.frequency4b !== '' ? parseInt(this.form.frequency4b, 10) : '';

      this.form.yearlyProductionRate =
        this.form.yearlyProductionRate !== '' ? parseInt(this.form.yearlyProductionRate, 10) : '';

      this.form.staffCost = this.form.staffCost !== '' ? parseInt(this.form.staffCost, 10) : '';
    },
    calculate() {
      this.formatNumber();

      const form = document.querySelector('.survey_form');
      const formSubmitButton = document.querySelector('.survey_submit-mirror');

      form.addEventListener('submit', () => {
        this.showResults = true;
      });

      const rejectRate = parseInt(this.form.rejectRate3a, 10);
      const yearlyProductionRate = parseInt(this.form.yearlyProductionRate, 10);
      const netSellingPrice = parseInt(this.form.netSellingPrice4a, 10);
      const staffCost = parseInt(this.form.staffCost, 10);
      const frequency4b = parseInt(this.form.frequency4b, 10);
      const followupCost3b = parseInt(this.form.followupCost3b, 10);

      if (this.path === 'a') {
        this.frr5 = parseInt(
          (rejectRate / 100) * yearlyProductionRate * netSellingPrice * (5 / 100) + staffCost
        ).toLocaleString('de');

        this.frr10 = parseInt(
          (rejectRate / 100) * yearlyProductionRate * netSellingPrice * (10 / 100) + staffCost
        ).toLocaleString('de');

        this.frr20 = parseInt(
          (rejectRate / 100) * yearlyProductionRate * netSellingPrice * (20 / 100) + staffCost
        ).toLocaleString('de');
      }

      if (this.path === 'b') {
        this.rfn60 = parseInt(
          (yearlyProductionRate / 100) * (frequency4b / 100) * followupCost3b * (60 / 100) +
            staffCost,
          10
        ).toLocaleString('de');

        this.rfn40 = parseInt(
          (yearlyProductionRate / 100) * (frequency4b / 100) * followupCost3b * (40 / 100) +
            staffCost,
          10
        ).toLocaleString('de');

        this.rfn20 = parseInt(
          (yearlyProductionRate / 100) * (frequency4b / 100) * followupCost3b * (20 / 100) +
            staffCost,
          10
        ).toLocaleString('de');
      }

      this.showResults = true;

      formSubmitButton.click();
    },
  }).mount('#roi-calculator');
};
