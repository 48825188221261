import { createApp } from 'petite-vue';

export default () => {
  $(document).ready(() => {
    $(window).keydown((event) => {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  });

  createApp({
    activeGroup: '1',
    lastGroup: [],
    form: {
      primaryInterest: '',
      customerInquiries: '',
      featureDegree: '50',
      customUI: '',
      serviceComponents: '',
      serviceComponentsArray: [],
    },
    license: '',
    score: '',
    showResults: false,
    userURL: 'user-url',
    previousGroup() {
      this.activeGroup = this.lastGroup.pop();
    },
    nextGroup(group) {
      this.lastGroup.push(this.activeGroup);
      this.activeGroup = group;

      this.formatNumber();
    },
    checkUserURL() {
      const userURL = sessionStorage.getItem('initial-userURL');

      if (userURL) {
        this.userURL = userURL;
      } else {
        this.userURL = window.location.href;
      }
    },
    formatNumber() {
      this.form.customerInquiries =
        this.form.customerInquiries !== '' ? parseInt(this.form.customerInquiries, 10) : '';

      this.form.featureDegree =
        this.form.featureDegree !== '' ? parseInt(this.form.featureDegree, 10) : '';
    },
    radioState(radio) {
      const groupAttribute = radio.getAttribute('name');

      const radioButtons = document.querySelectorAll(`input[name="${groupAttribute}"]`);

      radioButtons.forEach((button) => {
        if (button.checked === true) {
          button.parentElement.classList.add('is-active');
        }

        if (button.checked === false) {
          button.parentElement.classList.remove('is-active');
        }
      });
    },
    checkboxState(checkbox) {
      if (checkbox.checked === true) {
        checkbox.parentElement.classList.add('is-active');

        this.form.serviceComponentsArray.push(checkbox.value);
      }

      if (checkbox.checked === false) {
        const index = this.form.serviceComponentsArray.indexOf(checkbox.value);
        if (index > -1) {
          this.form.serviceComponentsArray.splice(index, 1);
        }

        checkbox.parentElement.classList.remove('is-active');
      }
    },
    calculate() {
      this.formatNumber();

      const form = document.querySelector('.survey_form');
      const formSubmitButton = document.querySelector('.survey_submit-mirror');

      form.addEventListener('submit', () => {
        this.showResults = true;
      });

      const customerInquiries = parseInt(this.form.customerInquiries, 10);
      const featureDegree = parseInt(this.form.featureDegree, 10);
      const customUI = parseInt(this.form.customUI, 10);
      const serviceComponentsCount = this.form.serviceComponentsArray.length;

      this.score = parseInt(
        customerInquiries * 4 + featureDegree + customUI + serviceComponentsCount * 10,
        10
      );

      if (this.score <= 125) {
        this.license = 'S';
      }

      if (this.score >= 126 && this.score <= 250) {
        this.license = 'M';
      }

      if (this.score >= 251) {
        this.license = 'L';
      }

      formSubmitButton.click();
    },
  }).mount('#roi-calculator');
};
