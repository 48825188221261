import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: 'body',
      start: 'top+=100 top',
      end: '+=300',
      scrub: 1,
      markers: false,
    },
  });

  tl.to('[global-animation="navigation"]', {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    duration: 1,
    ease: 'power4.out',
  });
};
