import gsap from 'gsap';

export default () => {
  const buttons = gsap.utils.toArray('.button');

  buttons.forEach((button) => {
    button.addEventListener('mouseenter', () => {
      gsap.to(button, {
        duration: 0.5,
        background: 'white',
        color: 'black',
      });
    });

    button.addEventListener('mouseleave', () => {
      gsap.to(button, {
        duration: 0.5,
        background: 'transparent',
        color: 'white',
      });
    });
  });
};
