import Navigo from 'navigo';

import LandingPageAnimation from './Components/Animation/LandingPage/LandingPage';
import ButtonHover from './Components/Animation/Global/ButtonHover';
import HoverGradient from './Components/Animation/Global/HoverGradient';
import ExpandText from './Components/Animation/Global/ExpandText';
import NavigationBg from './Components/Animation/Global/NavigationBg';

import ROICalculatorIndustry from './Components/Survey/Industry/ROICalculator';
import ROICalculatorIntegrator from './Components/Survey/Integrator/ROICalculator';

import GatewayHoverAnimation from './Components/Animation/Gateway/HoverAnimation';

const router = new Navigo('/');

router.on('/', () => {
  GatewayHoverAnimation();
});

router.on('/gateway-wartung', () => {
  GatewayHoverAnimation();
});

router.on('/fertigung/industrie', () => {
  ButtonHover();
  ExpandText();
  LandingPageAnimation();
  HoverGradient();
  NavigationBg();

  ROICalculatorIndustry();
});

router.on('/fertigung/integrator', () => {
  ButtonHover();
  ExpandText();
  LandingPageAnimation();
  HoverGradient();
  NavigationBg();

  ROICalculatorIntegrator();
});

router.on('/wartung/industrie', () => {
  ButtonHover();
  ExpandText();
  LandingPageAnimation();
  HoverGradient();
  NavigationBg();

  ROICalculatorIndustry();
});

router.on('/wartung/integrator', () => {
  ButtonHover();
  ExpandText();
  LandingPageAnimation();
  HoverGradient();
  NavigationBg();

  ROICalculatorIntegrator();
});

router.on('/survey/fertigung/survey-industrie-fertigung', () => {
  ROICalculatorIndustry();
});

router.on('/survey/fertigung/survey-integrator-fertigung', () => {
  ROICalculatorIndustry();
});

router.on('/survey/wartung/survey-industrie-wartung', () => {
  ROICalculatorIntegrator();
});

router.on('/survey/wartung/survey-integrator-wartung', () => {
  ROICalculatorIntegrator();
});

const userURLFromStorage = sessionStorage.getItem('initial-userURL');

if (!userURLFromStorage) {
  sessionStorage.setItem('initial-userURL', window.location.href);
}

router.notFound(() => true);

router.resolve();
