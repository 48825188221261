import gsap from 'gsap';

export default () => {
  const mm = gsap.matchMedia();

  document.querySelectorAll('[animation="hover-gradient"]').forEach((element) => {
    const bgImage = element.querySelector('[animation-element="bg-image"]');
    const bgGradientElement = element.querySelector('[animation-element="bg-gradient"]');
    const gradientStyle = bgGradientElement.getAttribute('gradient-style');

    const wrapper = element.querySelector('.production-chain_wrapper');

    gsap.set(bgGradientElement, { background: gradientStyle });

    element.addEventListener('mouseenter', () => {
      mm.add('(min-width: 992px)', () => {
        gsap.to(bgGradientElement, {
          duration: 0.5,
          opacity: 1,
        });

        gsap.to(bgImage, {
          duration: 0.5,
          opacity: 1,
        });

        gsap.to(wrapper, {
          duration: 0.5,
          borderColor: 'transparent',
        });
      });
    });

    element.addEventListener('mouseleave', () => {
      mm.add('(min-width: 992px)', () => {
        gsap.to(bgGradientElement, {
          duration: 0.5,
          opacity: 0,
        });

        gsap.to(bgImage, {
          duration: 0.5,
          opacity: 0,
        });

        gsap.to(wrapper, {
          duration: 0.5,
          borderColor: 'white',
        });
      });
    });
  });
};
